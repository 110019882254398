const en = {
  menu: {
    top: {
      gear: 'Settings',
      dashboard: 'Dashboard',
      planner: 'Planner',
      extracurriculars: 'Extracurriculars',
      essays: 'Essays',
      colleges: 'Colleges',
    },
    right: {
      settings: 'Settings',
      account: 'Account',
      logOut: 'Log Out',
    }
  },
  signedIn: {
    dashboard: {
      greetings: {
        welcome: 'Welcome back, {name}!',
      },
      profileProgress: {},
      deadlines: {},
      activities: {},
      essayEditor: {},
      planner: {},
    },
    academicPlanner: { comingSoon: 'Coming Soon' },
    extracurriculars: { comingSoon: 'Coming Soon' },
    essays: {
      newEssay: {
        title: 'New Essay',
      },
      existingEssays: {
        title: 'Existing Essays',
      },
      deadlines: {
        title: 'Deadlines',
      },
    },
    colleges: {
      profile: {
        gpa: 'GPA',
        weightedGpa: 'Weighted GPA',
        sat: 'SAT',
        act: 'ACT',
        viewProfile: 'View Profile',
        none: 'None',
      },
      collegeFilters: {
        title: 'Filters',
        clearFilters: 'Clear Filters',
        location: 'Location',
      },
      collegeList: {
        title: 'College List',
        search: 'Search a college by name',
      },
    },
    profile: {
      right: {
        personalInformation: {
          title: 'Personal Information',
          country: 'Country',
          state: 'State',
          parentalIncome: 'Parental Income',
          ethnicity: 'Ethnicity',
          gender: 'Gender',
          genders: {
            male: 'Male',
            female: 'Female',
            other: 'Other',
          },
          updateSuccess: 'Profile updated successfully!',
          updateError: 'There was an error updating your profile. {error}',
        },
        academics: {
          title: 'Academics',
          gpa: 'GPA',
          weightedGpa: 'Weighted GPA',
          sat: 'SAT',
          act: 'ACT',
        },
        highSchool: {
          title: 'High School',
          highSchool: 'High School Name',
          classRank: 'Class Rank',
          gradYear: 'Graduation Year',
        },
        update: 'Update',
      },
      left: {
        logOut: 'Log Out',
      },
    },
  },
  signedOut: {
    signIn: {
      left: {
        title: 'Welcome back to Aspired!',
        subTitle: 'Continue your college admissions journey',
      },
      right: {
        title: 'Sign in to your account',
        subtitle: 'Don\'t have an account?',
        getStarted: 'Get started',
        email: 'Email address',
        password: 'Password',
        forgotPassword: 'Forgot password?',
        signInButton: 'Sign in',
      }
    },
    signUp: {
      left: {
        title: 'Welcome to Aspired!',
        subTitle: 'Your guide to successful college admissions',
      },
      right: {
        title: 'Sign up for an account',
        email: 'Email',
        firstName: 'First Name',
        lastName: 'Last Name',
        password: 'Password',
        graduationYear: 'Graduation Year',
        highSchool: 'High School',
        signUpButton: 'Sign Up',
        subtitle: 'Already have an account?',
        alreadyHaveAccount: 'Sign in',
      }
    },
    forgotPassword: {
      left: {
        title: 'Hi, Welcome back',
        subTitle: 'More effectively with optimized workflows',
      },
      right: {
        title: 'Forgot your password?',
        subtitle: 'Please enter the email address associated with your account and we\'ll email you a link to reset your password.',
        email: 'Email address',
        sendRequest: 'Send Request',
        returnToSignIn: 'Return to sign in',
      },
    },
    updatePassword: {
      left: {
        title: 'Hi, Welcome back',
        subTitle: 'More effectively with optimized workflows',
      },
      right: {
        title: 'Request sent successfully!',
        subtitle: 'We\'ve sent a 6-digit confirmation email to your email. Please enter the code in below box to verify your email.',
        email: 'Email address',
        password: 'Password',
        passwordPlaceHolder: '6+ characters',
        confirmPassword: 'Confirm Password',
        updatePassword: 'Update Password',
        dontHaveCode: 'Don\'t have the code?',
        resendCode: 'Resend code',
        returnToSignIn: 'Return to sign in',
      }
    }
  },
  waitList: {
    title: 'Coming Soon',
    subTitle: 'Our app is on its way! Join our waitlist to be the first to know when we launch.',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email Address',
    highSchool: 'High School Name',
    city: 'City',
    state: 'State',
    graduationYear: 'Graduation Year',
    joinWaitlist: 'Join Waitlist',
    joinWaitlistSuccess: 'Thank you for joining our waitlist!',
    joinWaitlistError: 'There was an error joining the waitlist. Please try again.',
    submit: 'Submit',
    submitting: 'Submitting...',
  }
};

export default en;

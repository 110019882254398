import { useState, ChangeEvent } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Grid, TextField, Typography, Box } from '@mui/material';
import { PasswordIcon } from '../../../Icons';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';

interface Props {
  className?: string;
  strings: any;
  isSmallScreen: boolean;
}

interface ForgotPasswordFormData {
  email: string;
}

const inputs = [
  ['email'],
];

const ForgotPasswordRightSide = (props: Props) => {
  const { className, strings, isSmallScreen } = props;

  const navigate = useNavigate();

  const [formData, setFormData] = useState<ForgotPasswordFormData>({ email: '' });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((_formData) => ({ ..._formData, [e.target.name]: e.target.value }));
  };

  const handleSubmit = () => {
    navigate('/update-password?email=' + encodeURIComponent(formData.email));
  };

  const clicReturnToSignIn = () => navigate('/sign-in');

  return (
    <Grid className={className} item xs={12} md={8}>
      <div id='right-side'>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <PasswordIcon />
          <Typography variant='h4'>{strings.title}</Typography>
          <div id='subtitle'>
            <Typography variant='body2' color='#637381'>{strings.subtitle}</Typography>
          </div>
        </div>
        {inputs.map((inputRow, index) => (
          <Box
            display='flex'
            flexDirection={isSmallScreen ? 'column' : 'row'}
            gap={2}
            marginBottom={2}
            key={index}
          >
            {inputRow.map((input) => (
              <TextField
                key={input}
                label={(strings as any)[input]}
                variant='outlined'
                fullWidth
                name={input}
                value={(formData as any)[input]}
                onChange={handleChange}
                type='email'
                placeholder='example@gmail.com'
              />
            ))}
          </Box>
        ))}
        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={handleSubmit}
          sx={{ marginTop: 2 }}
        >
          {strings.sendRequest}
        </Button>
      </div>
      <div id='return-to-signin' onClick={clicReturnToSignIn}>
        <ArrowBackIosIcon />
        <Typography>
          {strings.returnToSignIn}
        </Typography>
      </div>
    </Grid>
  );
};

export default styled(ForgotPasswordRightSide)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 16px;

  #right-side {
    width: 100%;
    max-width: 420px;
    padding: 0 16px;

    #title {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    #subtitle {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      & > * {
        padding-right: 5px;
      }
    }
  }

  #return-to-signin {
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;
// src/App.tsx
import React, { useState, useRef } from 'react';
import { Box, styled, TextField } from '@mui/material';

interface OTPInputProps {
  className?: string;
  length: number;
  onChange: (otp: string) => void;
}

const OTPInput = ({ className, length, onChange }: OTPInputProps) => {
  const [otp, setOtp] = useState<string[]>(Array(length).fill(''));
  const inputs = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (/[^0-9]/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    onChange(newOtp.join(''));

    if (value && index < length - 1) {
      inputs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      inputs.current[index - 1]?.focus();
    }
  };

  return (
    <Box
      className={className}
      display='flex'
      flexDirection='row'
      justifyContent='space-between'
      alignItems='center'
    >
      {otp.map((data, index) => (
        <TextField
          key={index}
          value={data}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, index)}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(e, index)}
          inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
          className='otp-input'
          inputRef={(ref) => (inputs.current[index] = ref)}
          placeholder='-'
        />
      ))}
    </Box>


  );
};

export default styled(OTPInput)`
  width: 100%;

  .otp-input {
    width: 60px;
    text-align: center;
  }
`;
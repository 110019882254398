import { styled } from '@mui/material/styles';

interface Props {
    className?: string;
    strings: any;
}

const Planner = (props: Props) => {
    const { className } = props;

    return (
        <div className={className}>
            Planner
        </div>
    );
};

export default styled(Planner)`

`;

import { Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FetchAccount } from '../../../api_calls/response';
import { Dispatch, SetStateAction } from 'react';

interface Props {
    strings: any;
    spacing: number;
    updating: boolean;
    setAccountInfo: Dispatch<SetStateAction<FetchAccount>>;
    accountInfo: FetchAccount;
}

const HighSchool = (props: Props) => {
    const { strings, spacing, updating, accountInfo, setAccountInfo } = props;

    return (
        <Grid container item xs={12} className='profile-sections' spacing={spacing}>
            <Grid item xs={12}>
                <Typography variant='h4'>{strings.title}</Typography>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    size='small'
                    label={strings.highSchool}
                    value={(accountInfo as any).highSchool || ''}
                    name='highSchool'
                    onChange={e => setAccountInfo({ ...accountInfo, highSchool: e.target.value })}
                    fullWidth
                    disabled={updating}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    size='small'
                    label={strings.classRank}
                    value={(accountInfo as any).classRank || ''}
                    onChange={e => setAccountInfo({ ...accountInfo, classRank: +e.target.value })}
                    fullWidth
                    disabled={updating}
                    type='number'
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    size='small'
                    label={strings.gradYear}
                    value={(accountInfo as any).gradYear || ''}
                    onChange={e => setAccountInfo({ ...accountInfo, gradYear: +e.target.value })}
                    fullWidth
                    disabled={updating}
                    type='number'
                />
            </Grid>
        </Grid>
    );
};

export default styled(HighSchool)`

`;

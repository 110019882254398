import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface Props {
    className?: string;
    strings: any;
}

const ExistingEssay = (props: Props) => {
    const { className, strings } = props;

    return (
        <div className={className}>
            <Grid container>
                <Typography variant='h4'>{strings.title}</Typography>
            </Grid>
        </div>
    );
};

export default styled(ExistingEssay)`
    background-color: white;
    border-radius: 5px;
    padding: 10px;
`;

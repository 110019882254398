import { styled } from '@mui/material/styles';
import { language } from '../../../Language';
import { Grid } from '@mui/material';
import SignUpRightSide from './RightSide';
import SignUpLeftSide from './LeftSide';
import { FetchAccount } from '../../../api_calls/response';

interface Props {
    className?: string;
    isSmallScreen: boolean;
    localLogIn: (jwt: string, account: FetchAccount) => void;
}

const strings = language().signedOut.signUp;

const SignUp = (props: Props) => {
    const { className, isSmallScreen, localLogIn } = props;

    return (
        <Grid className={className} container>
            {!isSmallScreen && <SignUpLeftSide strings={strings.left} />}
            <SignUpRightSide isSmallScreen={isSmallScreen} localLogIn={localLogIn} strings={strings.right} />
        </Grid>
    );
};

export default styled(SignUp)`

`;

import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import DeadlineCard, { DeadlineCardProps } from './DeadlineCard';
import { useEffect, useState } from 'react';

interface Props {
    className?: string;
    strings: any;
}

const Deadlines = (props: Props) => {
    const { className, strings } = props;
    const [upcomingDeadlines, setUpcomingDeadlines] = useState<DeadlineCardProps[]>([]);
    useEffect(() => {
        setUpcomingDeadlines([
            {
                collegeName: 'University of California, Los Angeles',
                deadline: new Date('2024-11-30'),
                decisionType: 'Regular Decision'
            },
            {
                collegeName: 'University of California, Berkeley',
                deadline: new Date('2024-11-30'),
                decisionType: 'Regular Decision'
            },
        ]);
    }, []);

    return (
        <div className={className}>
            <Grid container spacing={1}>
                <Grid item>
                    <Typography variant='h4'>{strings.title}</Typography>
                </Grid>
                {upcomingDeadlines.map((deadlineInfo, index) => (
                    <Grid key={index} item xs={12}>
                        <DeadlineCard deadlineInfo={deadlineInfo} />
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default styled(Deadlines)`
    background-color: #e1e1e1;
    border-radius: 5px;
    padding: 10px;
`;

import { Alert, Button, CircularProgress, Grid, Snackbar, SnackbarCloseReason } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext, useState } from 'react';
import { storeContext } from '../../../store';
import { FetchAccount } from '../../../api_calls/response';
import { updateAccount } from '../../../api_calls';
import HighSchool from './HighSchool';
import Academics from './Academics';
import PersonalInformation from './PersonalInformation';

interface Props {
    className?: string;
    strings: any;
}

interface ActionFeedback {
    message: string;
    type: 'success' | 'error' | null;
}

const spacing = 2;

const ProfileRight = (props: Props) => {
    const { className, strings } = props;
    const { account, setAccount } = useContext(storeContext);

    const [actionFeedback, setActionFeedback] = useState<ActionFeedback>({ message: '', type: null });
    const [updating, setUpdating] = useState<boolean>(false);
    const [accountInfo, setAccountInfo] = useState<FetchAccount>({
        email: account.email,
        firstName: account.firstName,
        lastName: account.lastName,
        highSchool: account.highSchool,
        gradYear: account.gradYear,
        gender: account.gender,
        country: account.country,
        parentalIncome: account.parentalIncome,
        classRank: account.classRank,
        gpa: account.gpa,
        weightedGpa: account.weightedGpa,
        sat: account.sat,
        act: account.act,
    });

    const handleUpdateAccount = () => {
        setUpdating(true);
        updateAccount(accountInfo)
            .then(() => {
                setAccount(accountInfo);
                setActionFeedback({
                    message: strings.personalInformation.updateSuccess,
                    type: 'success',
                });
            })
            .catch((err) => {
                setActionFeedback({
                    message: strings.personalInformation.updateError.replace('{error}', err.message),
                    type: 'error',
                })
            })
            .finally(() => setUpdating(false));
    }

    const handleCloseSnackbar = (
        event?: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setActionFeedback((_actionFeedback) => ({ type: _actionFeedback.type, message: '' }))
    }

    return (
        <div className={className}>
            <Grid container spacing={1}>
                <PersonalInformation
                    strings={strings.personalInformation}
                    spacing={spacing}
                    updating={updating}
                    accountInfo={accountInfo}
                    setAccountInfo={setAccountInfo}
                />
                <Academics
                    strings={strings.academics}
                    spacing={spacing}
                    updating={updating}
                    accountInfo={accountInfo}
                    setAccountInfo={setAccountInfo}
                />
                <HighSchool
                    strings={strings.highSchool}
                    spacing={spacing}
                    updating={updating}
                    accountInfo={accountInfo}
                    setAccountInfo={setAccountInfo}
                />
                <Grid container item>
                    <Button
                        variant='outlined'
                        size='small'
                        onClick={handleUpdateAccount}
                        disabled={updating}
                    >
                        {updating ? <CircularProgress /> : strings.update}
                    </Button>
                </Grid>
            </Grid>
            {actionFeedback.message && (
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={!!actionFeedback.message}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                >
                    <Alert severity={actionFeedback.type as any}>{actionFeedback.message}</Alert>
                </Snackbar>
            )}
        </div>
    );
};

export default styled(ProfileRight)`
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid #cccccc;
    background-color: white;
    
    .profile-sections {
        display: flex;
        padding: 10px;
    }
`;

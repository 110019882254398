import { Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { QuerySchoolsResponse } from '../../api_calls/response';
import CollegeCard from './CollegeCard';
import { Dispatch, SetStateAction } from 'react';
import { CollegeFilter } from '.';

interface Props {
    className?: string;
    strings: any;
    colleges: QuerySchoolsResponse[];
    collegeFilters: CollegeFilter;
    setCollegeFilters: Dispatch<SetStateAction<CollegeFilter>>;
}

const CollegeList = (props: Props) => {
    const { className, strings, colleges, collegeFilters, setCollegeFilters } = props;

    return (
        <div className={className}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant='h4'>{strings.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        size='small'
                        variant='standard'
                        placeholder={strings.search}
                        value={collegeFilters.name}
                        onChange={(e) => setCollegeFilters({ ...collegeFilters, name: e.target.value })}
                    />
                </Grid>
                {colleges.map((college) => (
                    <Grid item xs={12} key={college.id}>
                        <CollegeCard college={college} />
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default styled(CollegeList)`
    background-color: white;
    padding: 10px;
    border-radius: 5px;
`;

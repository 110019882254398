import { styled } from '@mui/material/styles';
import { QuerySchoolsResponse } from '../../api_calls/response';
import { Typography } from '@mui/material';

interface Props {
    className?: string;
    college: QuerySchoolsResponse;
}

const CollegeCard = (props: Props) => {
    const { className, college } = props;

    return (
        <div className={className}>
            <div>
                <Typography variant='h6'>{college.name}</Typography>
            </div>
            <div>
                <Typography variant='body1'>{college.state}</Typography>
            </div>
        </div>
    );
};

export default styled(CollegeCard)`
    background-color: rgb(226, 226, 226);
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
`;

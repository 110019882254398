import { styled } from '@mui/material/styles';
import { language } from '../../../Language';
import { Grid } from '@mui/material';
import SignInRightSide from './RightSide';
import SignInLeftSide from './LeftSide';
import { FetchAccount } from '../../../api_calls/response';

interface Props {
  className?: string;
  isSmallScreen: boolean;
  localLogIn: (jwt: string, account: FetchAccount) => void;
}

const strings = language().signedOut.signIn;

const SignIn = (props: Props) => {
  const { className, isSmallScreen, localLogIn } = props;

  return (
    <Grid className={className} container>
      {!isSmallScreen && <SignInLeftSide strings={strings.left} />}
      <SignInRightSide isSmallScreen={isSmallScreen} localLogIn={localLogIn} strings={strings.right} />
    </Grid>
  );
};

export default styled(SignIn)`

`;

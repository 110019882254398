import { CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { language } from '../../Language';
import Profile from './Profile';
import CollegeFilters from './CollegeFilters';
import CollegeList from './CollegeList';
import { useEffect, useState } from 'react';
import { QuerySchoolsResponse } from '../../api_calls/response';
import { querySchools } from '../../api_calls';

interface Props {
    className?: string;
    initialLoading: boolean;
}

export interface CollegeFilter {
    name: string;
    state: string[];
}

const strings = language().signedIn.colleges;

const cleanFilter = ({ name: '', state: [] });

const Colleges = (props: Props) => {
    const { className, initialLoading } = props;
    const [colleges, setColleges] = useState<QuerySchoolsResponse[]>([]);
    const [collegeFilters, setCollegeFilters] = useState<CollegeFilter>(cleanFilter);

    useEffect(() => {
        querySchools(collegeFilters).then(setColleges).catch(console.error);
    }, [collegeFilters]);

    return (
        <div className={className}>
            <Grid container spacing={1}>
                {initialLoading ? <CircularProgress /> : (
                    <>
                        <Grid container item xs={12} md={4} spacing={2}>
                            <Grid item xs={12}>
                                <Profile strings={strings.profile} />
                            </Grid>
                            <Grid item xs={12}>
                                <CollegeFilters
                                    strings={strings.collegeFilters}
                                    collegeFilters={collegeFilters}
                                    setCollegeFilters={setCollegeFilters}
                                    clearCollegeFilters={() => setCollegeFilters(cleanFilter)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} md={8}>
                            <Grid item xs={12}>
                                <CollegeList
                                    strings={strings.collegeList}
                                    colleges={colleges}
                                    collegeFilters={collegeFilters}
                                    setCollegeFilters={setCollegeFilters}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </div>
    );
};

export default styled(Colleges)`
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

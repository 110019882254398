import _axios, { AxiosRequestConfig } from 'axios';
import { WaitlistFormData } from '../Pages/Entry/Waitlist';
import { apiBaseUrl } from '../environment';
import { SignUpFormData } from '../Pages/Entry/SignUp/RightSide';
import { SignInFormData } from '../Pages/Entry/SignIn/RightSide';
import { FetchAccount, JoinWaitlistResponse, QuerySchoolsResponse, SignInResponse, SignUpResponse } from './response';
import { CollegeFilter } from '../Pages/Colleges';
import qs from 'qs';

const POST = 'POST';
const GET = 'GET';
const STORE_KEY = 'aspired_local_store';

const debounce = (func: any, delay: number = 500): (queryParams: any) => Promise<any> => {
    let timeoutId: any;

    return (queryParams?: any) => {
        clearTimeout(timeoutId);

        return new Promise((resolve, reject) => {
            timeoutId = setTimeout(async () => {
                try {
                    resolve(await func(queryParams));
                } catch (error) {
                    reject(error);
                }
            }, delay);
        });
    };
};

export const getLocalJwt = () => localStorage.getItem(STORE_KEY);
export const setLocalJwt = (jwt: string) => localStorage.setItem(STORE_KEY, jwt);
export const clearLocalJwt = () => localStorage.removeItem(STORE_KEY);

const axiosInstance = _axios.create({ baseURL: apiBaseUrl, paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'comma' }) } });

const axios = async (axiosRequest: AxiosRequestConfig, authorized: boolean = true) => {
    try {
        if (authorized) {
            axiosRequest.headers = { ...axiosRequest.headers, Authorization: `Bearer ${getLocalJwt()}` };
        }

        return convertSnakeToCamel((await axiosInstance({ method: GET, ...axiosRequest })).data);
    } catch (err: any) {
        throw new Error(err.response.data.message || err.response.data);
    }
};

export const joinWaitlist = (data: WaitlistFormData): Promise<JoinWaitlistResponse> => axios({ url: '/public/waitlist', method: POST, data }, false);
export const signUp = (data: SignUpFormData): Promise<SignUpResponse> => axios({ url: '/public/sign-up', method: POST, data }, false);
export const signIn = (data: SignInFormData): Promise<SignInResponse> => axios({ url: '/public/sign-in', method: POST, data }, false);
export const fetchAccount = (): Promise<FetchAccount> => axios({ url: '/account' });
export const updateAccount = (data: FetchAccount): Promise<void> => axios({ url: '/account', method: POST, data });
export const querySchools = debounce((filters: CollegeFilter): Promise<QuerySchoolsResponse[]> => axios({ url: '/school', method: GET, params: filters }));

const convertSnakeToCamel = (obj: any): any => {
    if (Array.isArray(obj)) {
        return obj.map(convertSnakeToCamel);
    } else if (obj !== null && obj.constructor === Object) {
        return Object.keys(obj).reduce((acc, key) => {
            const camelKey = key.replace(/([-_][a-z])/ig, ($1) => $1.toUpperCase().replace('-', '').replace('_', ''));
            return { ...acc, [camelKey]: convertSnakeToCamel(obj[key]) };
        }, {});
    } else {
        return obj;
    }
}

import { styled } from '@mui/material/styles';
import { FetchAccount } from '../../../api_calls/response';
import { Dispatch, SetStateAction } from 'react';
import { Grid, TextField, Typography } from '@mui/material';

interface Props {
    strings: any;
    spacing: number;
    updating: boolean;
    accountInfo: FetchAccount;
    setAccountInfo: Dispatch<SetStateAction<FetchAccount>>;
}

const Academics = (props: Props) => {
    const { strings, spacing, accountInfo, setAccountInfo, updating } = props;

    return (
        <Grid container item xs={12} className='profile-sections' spacing={spacing}>
            <Grid item xs={12}>
                <Typography variant='h4'>{strings.title}</Typography>
            </Grid>
            {['gpa', 'weightedGpa', 'sat', 'act'].map((fieldName) => (
                <Grid item xs={6}>
                    <TextField
                        size='small'
                        label={strings[fieldName]}
                        value={(accountInfo as any)[fieldName] || ''}
                        onChange={e => setAccountInfo({ ...accountInfo, [fieldName]: +e.target.value })}
                        fullWidth
                        disabled={updating}
                        type='number'
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default styled(Academics)`

`;

import { CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Deadlines from './Deadlines';
import NewEssay from './NewEssay';
import ExistingEssays from './ExistingEssays';
import { language } from '../../Language';

interface Props {
    className?: string;
    initialLoading: boolean;
}

const strings = language().signedIn.essays;

const Essays = (props: Props) => {
    const { className, initialLoading } = props;

    return (
        <div className={className}>
            <Grid container spacing={1}>
                {initialLoading ? <CircularProgress /> : (
                    <>
                        <Grid container item xs={12} md={8} spacing={1}>
                            <Grid item xs={12}>
                                <NewEssay strings={strings.newEssay} />
                            </Grid>
                            <Grid item xs={12}>
                                <ExistingEssays strings={strings.existingEssays} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Deadlines strings={strings.deadlines} />
                        </Grid>
                    </>
                )}
            </Grid>
        </div>
    );
};

export default styled(Essays)`
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

import { Button, Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext } from 'react';
import { storeContext } from '../../store';
import { useNavigate } from 'react-router-dom';

interface Props {
    className?: string;
    strings: any;
}

const Profile = (props: Props) => {
    const { className, strings } = props;
    const { account } = useContext(storeContext);
    const navigate = useNavigate();

    return (
        <div className={className}>
            <Grid container spacing={1}>
                <Grid container item spacing={1}>
                    <Grid item xs={6} alignContent='center'>
                        <Typography variant='h4'>{account.firstName}</Typography>
                    </Grid>
                    <Grid item xs={6} alignContent='center'>
                        <Button
                            variant='contained'
                            color='primary'
                            size='small'
                            onClick={() => navigate('/profile')}
                        >
                            {strings.viewProfile}
                        </Button>
                    </Grid>
                </Grid>

                {['gpa', 'weightedGpa', 'sat', 'act'].map((fieldName) => (
                    <Grid item xs={6} key={fieldName}>
                        <TextField
                            size='small'
                            label={strings[fieldName]}
                            value={(account as any)[fieldName] || ''}
                            fullWidth
                            placeholder={'None'}
                            disabled={true}
                            type='number'
                        />
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default styled(Profile)`
    background-color: white;
    padding: 10px;
    border-radius: 5px;
`;

import { CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Status from './Left';
import PersonalInformation from './Right';
import { language } from '../../Language';

interface Props {
    className?: string;
    initialLoading: boolean;
}

const strings = language().signedIn.profile;

const Profile = (props: Props) => {
    const { className, initialLoading } = props;

    return (
        <div className={className}>
            <Grid container spacing={1}>
                {initialLoading ? <CircularProgress /> : (
                    <>
                        <Grid item xs={12} md={3}>
                            <Status strings={strings.left} />
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <PersonalInformation strings={strings.right} />
                        </Grid>
                    </>
                )}
            </Grid>
        </div>
    );
};

export default styled(Profile)`
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

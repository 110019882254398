import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

interface Props {
  className?: string;
  strings: any;
}

const ForgotPasswordLeftSide = (props: Props) => {
  const { className, strings } = props;

  return (
    <Grid className={className} item md={4}>
      <Typography variant='h3'>{strings.title}</Typography>
      <Typography variant='body2' color={'#637381'}>{strings.subTitle}</Typography>
    </Grid>
  );
};

export default styled(ForgotPasswordLeftSide)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;

  & > * {
    padding-top: 5px;
  }
`;

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface Props {
    className?: string;
    deadlineInfo: DeadlineCardProps;
}

export type DecisionType = 'Regular Decision' | 'Early Decision' | 'Early Action' | 'Rolling Admission';

export interface DeadlineCardProps {
    collegeName: string;
    deadline: Date;
    decisionType: DecisionType;
}

const DeadlineCard = (props: Props) => {
    const { className, deadlineInfo: { collegeName, deadline, decisionType } } = props;

    return (
        <div className={className}>
            <Typography variant='h5'>{collegeName}</Typography>
            <Typography variant='subtitle1' >{decisionType}</Typography>
            <Typography variant='h6'>{deadline.toDateString()}</Typography>
        </div>
    );
};

export default styled(DeadlineCard)`
    padding: 10px;
    border-radius: 5px;
    background-color: white;
`;

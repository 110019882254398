import { FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FetchAccount, Gender } from '../../../api_calls/response';
import { Dispatch, SetStateAction } from 'react';

interface Props {
    strings: any;
    spacing: number;
    updating: boolean;
    accountInfo: FetchAccount;
    setAccountInfo: Dispatch<SetStateAction<FetchAccount>>;
}

const PersonalInformation = (props: Props) => {
    const { spacing, strings, updating, accountInfo, setAccountInfo } = props;

    return (
        <Grid container item xs={12} className='profile-sections' spacing={spacing}>
            <Grid item xs={12}>
                <Typography variant='h4'>{strings.title}</Typography>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    size='small'
                    label={strings.country}
                    value={(accountInfo as any).country}
                    onChange={e => setAccountInfo({ ...accountInfo, country: e.target.value })}
                    fullWidth
                    disabled={updating}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    size='small'
                    label={strings.parentalIncome}
                    value={(accountInfo as any).parentalIncome || ''}
                    onChange={e => setAccountInfo({ ...accountInfo, parentalIncome: +e.target.value })}
                    fullWidth
                    disabled={updating}
                    type='number'
                />
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth size='small' >
                    <InputLabel>{strings.gender}</InputLabel>
                    <Select
                        value={accountInfo.gender}
                        label={strings.gender}
                        size='small'
                        onChange={e => setAccountInfo({ ...accountInfo, gender: e.target.value as Gender })}
                    >
                        <MenuItem value={Gender.Male}>{strings.genders.male}</MenuItem>
                        <MenuItem value={Gender.Female}>{strings.genders.female}</MenuItem>
                        <MenuItem value={Gender.Other}>{strings.genders.other}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default styled(PersonalInformation)`

`;

import { Autocomplete, Button, Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { States } from '../../lib';
import { CollegeFilter } from '.';
import { Dispatch, SetStateAction } from 'react';

interface Props {
    className?: string;
    strings: any;
    collegeFilters: CollegeFilter;
    setCollegeFilters: Dispatch<SetStateAction<CollegeFilter>>;
    clearCollegeFilters: () => void;
}

const CollegeFilters = (props: Props) => {
    const { className, strings, collegeFilters, setCollegeFilters, clearCollegeFilters } = props;

    return (
        <div className={className}>
            <Grid container>
                <Grid item xs={12} display='flex' justifyContent='space-between' alignItems='center'>
                    <Typography variant='h4'>{strings.title}</Typography>
                    <Button
                        onClick={clearCollegeFilters}
                        variant='outlined'
                    >
                        {strings.clearFilters}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='subtitle1'>{strings.location}</Typography>
                    <Autocomplete
                        multiple
                        options={Object.keys(States)}
                        getOptionLabel={(option) => (States as any)[option]}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant='standard'
                                value={collegeFilters.state}
                            />
                        )}
                        onChange={(_, value) => setCollegeFilters({ ...collegeFilters, state: value })}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default styled(CollegeFilters)`
    background-color: white;
    padding: 10px;
    border-radius: 5px;
`;

import { styled } from '@mui/material/styles';

interface Props {
    className?: string;
    strings: any;
}

const EssayEditor = (props: Props) => {
    const { className } = props;

    return (
        <div className={className}>
            EssayEditor
        </div>
    );
};

export default styled(EssayEditor)`

`;

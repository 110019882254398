import { CircularProgress, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { language } from '../../Language';

interface Props {
    className?: string;
    initialLoading: boolean;
}

const strings = language().signedIn.academicPlanner;

const AcademicPlanner = (props: Props) => {
    const { className, initialLoading } = props;

    return (
        <div className={className}>
            <Grid container spacing={1} justifyContent='center'>
                {initialLoading ? <CircularProgress /> : (
                    <Typography variant='h3'>{strings.comingSoon}</Typography>
                )}
            </Grid>
        </div>
    );
};

export default styled(AcademicPlanner)`
    padding: 5px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
`;

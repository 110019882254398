import { styled } from '@mui/material/styles';
import { Drawer, Grid, Typography } from '@mui/material';
import SideMenuButtons from './SideMenuButtons';
import { language } from '../../Language';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';
import { useContext } from 'react';
import { storeContext } from '../../store';
import { useNavigate } from 'react-router-dom';

interface Props {
  className?: string;
  isOpen: boolean;
  toggleSideMenu: () => void;
}

const strings = language().menu.right;

const SideMenu = (props: Props) => {
  const { className, isOpen, toggleSideMenu } = props;
  const { localLogOut } = useContext(storeContext);

  const navigate = useNavigate();

  const handleLogOut = () => {
    toggleSideMenu();
    localLogOut();
  }

  const handleToProfile = () => {
    toggleSideMenu();
    navigate('/profile');
  }

  return (
    <Drawer
      open={isOpen}
      anchor='right'
      onClose={toggleSideMenu}
    >
      <Grid container className={className}>
        <SideMenuButtons strings={strings} toggleSideMenu={toggleSideMenu} />
        <Grid item className='side-menu-item' onClick={handleToProfile}>
          <AccountBoxIcon />
          <Typography variant='h6'>{strings.account}</Typography>
        </Grid>
        <Grid item className='side-menu-item' onClick={handleLogOut}>
          <LogoutIcon />
          <Typography variant='h6'>{strings.logOut}</Typography>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default styled(SideMenu)`
  width: 360px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  
  .side-menu-item {
    padding: 10px;
    display: flex;
    border-radius: 10px;

    &:hover {
      cursor: pointer;
      background-color: lightgray;
    }
  }
`;

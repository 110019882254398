import { useState, ChangeEvent } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Grid, TextField, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OtpInput from './OtpInput';

interface Props {
  className?: string;
  strings: any;
  email?: string;
  isSmallScreen: boolean;
}

interface UpdatePasswordFormData {
  email: string;
  otp: string;
  password: string;
  confirmPassword: string;
}

const inputs = [
  ['email'],
  ['otp'],
  ['password', 'confirmPassword'],
];

const UpdatePasswordRightSide = (props: Props) => {
  const { className, strings, email, isSmallScreen } = props;

  const [formData, setFormData] = useState<UpdatePasswordFormData>({
    email: email || '',
    otp: '',
    password: '',
    confirmPassword: '',
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((_formData) => ({ ..._formData, [e.target.name]: e.target.value }));
  };

  const handleOtpChange = (otp: string) => {
    setFormData((_formData) => ({ ..._formData, otp }));
  };

  const handleSubmit = () => {
    console.log('Update password', formData);
  };

  const navigate = useNavigate();
  const navigateToSignIn = () => navigate('/sign-in');

  return (
    <Grid className={className} item xs={12} md={8}>
      <div id='right-side'>
        <div>
          <Typography variant='h4'>{strings.title}</Typography>
        </div>
        <div id='subtitle'>
          <Typography variant='body2' color='#637381'>{strings.subtitle}</Typography>
        </div>
        {inputs.map((inputRow, index) => (
          <Box
            display='flex'
            flexDirection={isSmallScreen ? 'column' : 'row'}
            gap={2}
            marginBottom={2}
            key={index}
          >
            {inputRow.map((input) => {
              if (input === 'otp') {
                return (
                  <OtpInput
                    key={input}
                    length={6}
                    onChange={handleOtpChange}
                  />
                );
              } else {
                return (
                  <TextField
                    key={input}
                    label={(strings as any)[input]}
                    variant='outlined'
                    fullWidth
                    name={input}
                    value={(formData as any)[input]}
                    onChange={handleChange}
                    type={input.match('[Pp]assword') ? 'password' : 'text'}
                    placeholder={input === 'email' ? 'example@gmail.com' : ''}
                    disabled={input === 'email'}
                  />
                );
              }
            })}
          </Box>
        ))}

        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={handleSubmit}
          sx={{ marginTop: 2 }}
        >
          {strings.updatePassword}
        </Button>

        <div id='no-code'>
          <Typography variant='body2' color='#637381'>{strings.dontHaveCode}</Typography>
          <Typography
            id='resend-code'
            variant='body2'
            onClick={() => console.log('Resend code')}
          >
            {strings.resendCode}
          </Typography>
        </div>

        <div id='return-to-signin' onClick={navigateToSignIn}>
          <ArrowBackIosIcon />
          <Typography>
            {strings.returnToSignIn}
          </Typography>
        </div>
      </div>
    </Grid>
  );
};

export default styled(UpdatePasswordRightSide)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 16px;
  
  #right-side {
    width: 100%;
    max-width: 420px;
    padding: 0 16px;

    #title {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    #subtitle {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;

      & > * {
        padding-right: 5px;
      }
    }

    #no-code {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 10px;

      & > * {
        padding-right: 5px;
      }

      #resend-code {
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    #return-to-signin {
      padding-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
`;
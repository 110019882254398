import { styled } from '@mui/material/styles';
import { useContext } from 'react';
import { storeContext } from '../../store';
import { Typography } from '@mui/material';

interface Props {
    className?: string;
    strings: any;
}

const Greetings = (props: Props) => {
    const { className, strings } = props;
    const { account } = useContext(storeContext);

    return (
        <Typography variant='h4' className={className}>
            {strings.welcome.replace('{name}', account.firstName + ' ' + account.lastName)}
        </Typography>
    );
};

export default styled(Greetings)`
    background-color: unset !important;

    &:hover {
        transform: unset !important;
        cursor: unset !important;
    }
`;
